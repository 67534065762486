//
// Form Wizard
//

.form-wizard-wrapper {
    label {
      font-size: 14px;
      text-align: right;
    }
  }
  
  .wizard{

    ul{
        list-style: none !important;
        padding: 0;
        margin: 0;
    }
    // step
    >.steps {

        > ul > li {
            width: 25%;
        }

        .current-info{
            position: absolute;
            left: -999em;
        }

      a, a:active, a:hover {
        margin: 3px;
        padding: 15px;
        display: block;
        width: auto;
        border-radius: 5px;
      }
  
      .current {
        a, a:active, a:hover {
          background-color: $primary;
          color: $white;
          .number {
            border: 2px solid $white
          }
        }
      }
  
      .disabled, .done {
        a, a:active, a:hover {
          background-color: lighten($primary,30%);
          color: $primary;
          .number {
            border-color: $primary;
          }
        }
      }
  
      .number {
        font-size: 16px;
        padding: 5px;
        border-radius: 50%;
        border: 2px solid $white;
        width: 38px;
        display: inline-block;
        font-weight: 500;
        text-align: center;
        margin-right: 10px;
        background-color: rgba($primary, 0.25);
      }
    }
  
    // content
    >.content {
      background-color: transparent;
      margin: 0 5px;
      border-radius: 0;
      min-height: 150px;
      > .title{
        position: absolute;
        left: -999em;
      }
      >.body {
        width: 100%;
        height: 100%;
        padding: 30px 0 0;
        position: static
      }
    }
  
    // actions
  
    >.actions {
        position: relative;
        display: block;
        text-align: right;
        width: 100%;
        > ul {
            display: inline-block;
            text-align: right;
             > li{
                display: block;
                margin: 0 0.5em;
            }
        }
      a, a:active, a:hover {
        background-color: $primary;
        border-radius: 4px;
        padding: 8px 15px;
        color: $white;
      }
  
      
      .disabled {
        a, a:active, a:hover {
          opacity: .65;
          background-color: $primary;
          color: $white;
          cursor: not-allowed;
        }
      }
    }

    > .steps, > .actions {
        > ul {
            > li{
                float: left;
            }
        }
    }
    
  }
  
  
  @media (max-width: 768px) {
    .wizard {
      > .steps {
        > ul {
          > li {
            width: 50%;
          }
        }
      }
    }
    .form-wizard-wrapper {
      label {
        text-align: left;
      }
    }
  }
  
  @media (max-width: 520px) {
    .wizard {
      > .steps {
        > ul {
          > li {
            width: 100%;
          }
        }
      }
    }
  }