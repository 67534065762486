//
// Page-title
//

.page-title-box {
  padding: 24px 0px;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
  }

  h4 {
    font-weight: 700;
  }
}
